<template>
    <div class="main-splash">
        <div class="position-relative h-100">
            <div class="login-model-pop-up-sec login-model-popup position-relative">
                <p class="parkd-text">Park’D… found one! We solved parking for you. For good.
                    ParkdD’ is an online marketplace for sharing parking spaces. The company's marketplace allows
                    businesses
                    and
                    homeowners to list their private parking spaces for rent by the hour (no more dreading the beach,
                    your
                    nephew’s graduation, game day…the mall!) And, for those seeking parking spaces to find affordable
                    and
                    convenient parking, thereby enabling customers to make an effortless and substantial income from
                    their
                    underutilized parking spaces. Or get involved for the cause: We know what a valuable source of
                    real-time,
                    geographically targeted data Park’D is. As we cultivate these insights (with intention to monetize
                    with
                    discretion/to be utilized in only the smartest ways). We vow 20% of any data monetized towards
                    climate.
                    Let’s increase our quality of life. In every way possible.
                    Park’D, found one!</p>
                <div class="modal  fade" id="login-btn" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true" data-bs-backdrop="static" data-bs-target="#staticBackdrop">
                    <div v-show="showModal" class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <button ref="close_btn" type="button" class="btn-close cstm-btn-close" @click="closeModal()"
                                aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                            <div class="modal-heading-inner d-flex flex-column align-items-center justify-content-center text-center popup-heading">
                                <h3>COMING SOON!</h3>
                                <p>Please subscribe here to get notified.</p>
                            </div>
                            <div class="modal-body cstm-modal-body">
                                <div class="login-page">
                                    <div class="right-login">
                                        <input type="text" :value="form.email" class="input" maxlength="25"
                                            placeholder="EMAIL*" @input="isAlphanumeric($event)" />
                                        <div v-if="showEmailValidationError" class="error email-error" @blur="validateEmail">
                                            Invalid email
                                        </div>

                                        <input type="phone" v-model="form.phone" maxlength="20"
                                            placeholder="PHONE NUMBER" class="input" />

                                        <div v-if="showPhoneValidationError" class="error phone-error" @blur="validatePhone">
                                            Invalid phone
                                        </div>

                                        <button @click="handleUserSubscription()" class="submit-button mb-2"
                                            :disabled="loading" :class="loading ? 'disabled-btn' : ''">
                                            SUBSCRIPTION
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { Modal } from "bootstrap";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import emailjs from 'emailjs-com';
export default {
    name: "Home",
    data() {
        return {
            showModal: false,
            loading: true,
            form: {
                code: 91,
                phone: "",
                email: "",
            },
            showEmailValidationError: false,
            showPhoneValidationError: false,
            modal: null,
            hasSubmitted: false,
        };
    },
    mounted() {

        if (localStorage.getItem("hasSubmitted")) {
            this.hasSubmitted = true;
        }
        if (!this.hasSubmitted) {
            setTimeout(() => {
                this.showModal = true;
                this.closeLogin = new Modal("#login-btn");
                this.closeLogin.show();
            }, 5000);
        }
        this.closeLogin = new Modal("#login-btn");
        this.closeLogin.show();


    },
    methods: {
        isAlphanumeric(evt) {
            let newValue = evt.target.value;
            if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(newValue)) {
                this.form.email = newValue;
                this.loading = true
            } else {
                this.loading = false
                this.form.email = newValue
            }
            this.loading = this.form.email ? false : true;
        },

        handleUserSubscription() {
            this.validateEmail()
            if (this.form.phone) {
                this.validatePhone()
            }
            if (!this.showEmailValidationError && !this.showPhoneValidationError) {
                this.sendEmail();
                this.closeModal();
                toast.success("Thank You for Subscribing", {
                    autoClose: 2000,
                });
                localStorage.setItem("hasSubmitted", true);
                this.hasSubmitted = true;
            }
        },

        validateEmail() {
            const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
            if (!emailRegex.test(this.form.email)) {
                this.showEmailValidationError = true;
            } else {
                this.showEmailValidationError = false;
            }
        },

        validatePhone() {
            const phoneRegex = /^\d{10}$|^(\+\d{1,3})?[\s()-]?\d{1,3}[\s()-]?\d{1,4}[\s()-]?\d{1,4}[\s()-]?\d{1,9}$/;
            if (!phoneRegex.test(this.form.phone)) {
                this.showPhoneValidationError = true;
            } else {
                this.showPhoneValidationError = false;
            }
        },

        async sendEmail() {
            emailjs.init('SfWbnoq8BlONfTNSi');
            const templateParams = {
                to_name: 'Ritesh',
                from_email: this.form.email,
                message: `Email: ${this.form.email}\n
                Phone: ${this.form.phone}`
            };

            emailjs.send('service_fh8ekni', 'template_qupmyxw', templateParams)
                .then((response) => {
                    console.log('Email sent successfully:', response);
                })
                .catch((error) => {
                    console.error('Error sending email:', error);
                });
        },
        closeModal() {
            this.closeLogin.hide();
        },
    },
};
</script>